<h2 class="govuk-heading-m">{{ basicTitle }}</h2>
<dl class="govuk-summary-list govuk-summary-list--no-border asc-summarylist-border-top asc-summarylist-border-bottom">
  <div class="govuk-summary-list__row">
    <dt class="govuk-summary-list__key govuk-!-padding-right-0 govuk-!-width-one-half">Name or ID number</dt>
    <dd class="govuk-summary-list__value" [ngClass]="{ 'govuk-!-padding-left-9': !mandatoryDetailsPage }">
      {{ worker.nameOrId }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="' basic record details'"
        [link]="mandatoryDetailsPage ? getMandatoryDetailsRoute() : getRoutePath('staff-details', wdfView)"
        [hasData]="true"
      ></app-summary-record-change>
    </dd>
  </div>

  <div class="govuk-summary-list__row">
    <dt class="govuk-summary-list__key">Main job role</dt>
    <dd class="govuk-summary-list__value" [ngClass]="{ 'govuk-!-padding-left-9': !mandatoryDetailsPage }">
      <app-summary-record-value
        [wdfView]="wdfView"
        [wdfValue]="worker.wdf?.mainJob"
        [overallWdfEligibility]="overallWdfEligibility"
      >
        <ng-container *ngIf="worker.mainJob.other; else title">
          <span>{{ worker.mainJob.other }}</span>
        </ng-container>
        <ng-template #title>
          <span>{{ worker.mainJob.title }}</span>
        </ng-template>
      </app-summary-record-value>
      <app-wdf-field-confirmation
        *ngIf="
          canEditWorker &&
          wdfView &&
          worker.wdf?.mainJob.isEligible === 'Yes' &&
          !worker.wdf?.mainJob.updatedSinceEffectiveDate
        "
        [changeLink]="getRoutePath('staff-details',wdfView)"
        (fieldConfirmation)="this.confirmField('mainJob')"
        [workerUid]="worker.uid"
      ></app-wdf-field-confirmation>
    </dd>
  </div>

  <div class="govuk-summary-list__row">
    <dt class="govuk-summary-list__key">Contract type</dt>
    <dd class="govuk-summary-list__value" [ngClass]="{ 'govuk-!-padding-left-9': !mandatoryDetailsPage }">
      <app-summary-record-value
        [wdfView]="wdfView"
        [wdfValue]="worker.wdf?.contract"
        [overallWdfEligibility]="overallWdfEligibility"
      >
        <ng-container *ngIf="showPoolBankTag; else notPoolBankTag">Pool, Bank</ng-container>
        <ng-template #notPoolBankTag>
          {{ worker.contract }}
        </ng-template>
      </app-summary-record-value>
      <app-wdf-field-confirmation
        *ngIf="
          canEditWorker &&
          wdfView &&
          worker.wdf?.contract.isEligible === 'Yes' &&
          !worker.wdf?.contract.updatedSinceEffectiveDate
        "
        [changeLink]="getRoutePath('staff-details',wdfView)"
        (fieldConfirmation)="this.confirmField('contract')"
        [workerUid]="worker.uid"
      ></app-wdf-field-confirmation>
    </dd>
  </div>
</dl>
