<h2 class="govuk-heading-m">Training and qualifications</h2>
<dl class="govuk-summary-list asc-summarylist-border-top govuk-!-margin-bottom-0">
  <div class="govuk-summary-list__row">
    <dt class="govuk-summary-list__key govuk-!-width-one-half">Care Certificate</dt>

    <dd class="govuk-summary-list__value govuk-!-padding-left-9">
      <app-summary-record-value
        [wdfView]="wdfView"
        [wdfValue]="worker.wdf?.careCertificate"
        [overallWdfEligibility]="overallWdfEligibility"
      >
        {{ worker.careCertificate || '-' }}
      </app-summary-record-value>
      <app-wdf-field-confirmation
        *ngIf="
          canEditWorker &&
          wdfView &&
          worker.wdf?.careCertificate.isEligible === 'Yes' &&
          !worker.wdf?.careCertificate.updatedSinceEffectiveDate &&
          worker.careCertificate !== 'Yes, completed'
        "
        [changeLink]="getRoutePath('care-certificate',wdfView)"
        (fieldConfirmation)="this.confirmField('careCertificate')"
        (setReturnClicked)="this.setReturn()"
        [workerUid]="worker.uid"
      ></app-wdf-field-confirmation>
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        *ngIf="
          !(
            wdfView &&
            worker.wdf?.careCertificate.isEligible === 'Yes' &&
            !worker.wdf?.careCertificate.updatedSinceEffectiveDate &&
            worker.careCertificate !== 'Yes, completed'
          )
        "
        [explanationText]="' care certificate'"
        [link]="getRoutePath('care-certificate', wdfView)"
        [hasData]="!!worker.careCertificate"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>

  <div class="govuk-summary-list__row">
    <dt class="govuk-summary-list__key">Apprenticeship training</dt>
    <dd class="govuk-summary-list__value govuk-!-padding-left-9">
      {{ worker.apprenticeshipTraining || '-' | closedEndedAnswer }}
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="' apprenticeship training'"
        [link]="getRoutePath('apprenticeship-training', wdfView)"
        [hasData]="!!worker.apprenticeshipTraining"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>

  <div class="govuk-summary-list__row">
    <dt class="govuk-summary-list__key">Has a social care qualification</dt>
    <dd class="govuk-summary-list__value govuk-!-padding-left-9">
      <app-summary-record-value
        [wdfView]="wdfView"
        [wdfValue]="worker.wdf?.qualificationInSocialCare"
        [overallWdfEligibility]="overallWdfEligibility"
      >
        {{ worker.qualificationInSocialCare || '-' | closedEndedAnswer }}
      </app-summary-record-value>
      <app-wdf-field-confirmation
        *ngIf="
          canEditWorker &&
          wdfView &&
          worker.wdf?.qualificationInSocialCare.isEligible === 'Yes' &&
          !worker.wdf?.qualificationInSocialCare.updatedSinceEffectiveDate &&
          worker.qualificationInSocialCare !== 'Yes'
        "
        [changeLink]="getRoutePath('social-care-qualification',wdfView)"
        (fieldConfirmation)="this.confirmField('qualificationInSocialCare')"
        (setReturnClicked)="this.setReturn()"
        [workerUid]="worker.uid"
      ></app-wdf-field-confirmation>
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        *ngIf="
          !(
            wdfView &&
            worker.wdf?.qualificationInSocialCare.isEligible === 'Yes' &&
            !worker.wdf?.qualificationInSocialCare.updatedSinceEffectiveDate &&
            worker.qualificationInSocialCare !== 'Yes'
          )
        "
        [explanationText]="' relevant social care qualification'"
        [link]="getRoutePath('social-care-qualification', wdfView)"
        [hasData]="!!worker.qualificationInSocialCare"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>

  <div class="govuk-summary-list__row" *ngIf="displaySocialCareQualifications">
    <dt class="govuk-summary-list__key">Highest level of social care qualification</dt>
    <dd class="govuk-summary-list__value govuk-!-padding-left-9">
      <app-summary-record-value
        [wdfView]="wdfView"
        [wdfValue]="worker.wdf?.socialCareQualification"
        [overallWdfEligibility]="overallWdfEligibility"
      >
        {{ worker.socialCareQualification?.title | closedEndedAnswer }}
      </app-summary-record-value>
      <app-wdf-field-confirmation
        *ngIf="
          canEditWorker &&
          wdfView &&
          worker.wdf?.socialCareQualification.isEligible === 'Yes' &&
          !worker.wdf?.socialCareQualification.updatedSinceEffectiveDate
        "
        [changeLink]="getRoutePath('social-care-qualification-level',wdfView)"
        (fieldConfirmation)="
          this.confirmField('socialCareQualification'); this.confirmField('qualificationInSocialCare')
        "
        (setReturnClicked)="this.setReturn()"
        [workerUid]="worker.uid"
      ></app-wdf-field-confirmation>
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        *ngIf="
          !(
            wdfView &&
            worker.wdf?.socialCareQualification.isEligible === 'Yes' &&
            !worker.wdf?.socialCareQualification.updatedSinceEffectiveDate
          )
        "
        [explanationText]="' social care qualification level'"
        [link]="getRoutePath('social-care-qualification-level', wdfView)"
        [hasData]="!!worker.socialCareQualification"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>

  <div class="govuk-summary-list__row">
    <dt class="govuk-summary-list__key">Other qualifications</dt>
    <dd class="govuk-summary-list__value govuk-!-padding-left-9">
      <app-summary-record-value
        [wdfView]="wdfView"
        [wdfValue]="worker.wdf?.otherQualification"
        [overallWdfEligibility]="overallWdfEligibility"
      >
        {{ worker.otherQualification || '-' | closedEndedAnswer }}
      </app-summary-record-value>
      <app-wdf-field-confirmation
        *ngIf="
          canEditWorker &&
          wdfView &&
          worker.wdf?.otherQualification.isEligible === 'Yes' &&
          !worker.wdf?.otherQualification.updatedSinceEffectiveDate &&
          worker.otherQualification !== 'Yes'
        "
        [changeLink]="getRoutePath('other-qualifications',wdfView)"
        (fieldConfirmation)="this.confirmField('otherQualification')"
        (setReturnClicked)="this.setReturn()"
        [workerUid]="worker.uid"
      ></app-wdf-field-confirmation>
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        [explanationText]="' other qualifications'"
        [link]="getRoutePath('other-qualifications', wdfView)"
        [hasData]="!!worker.otherQualification"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>

  <div class="govuk-summary-list__row" *ngIf="displayOtherQualifications">
    <dt class="govuk-summary-list__key">Highest level of other qualifications</dt>
    <dd class="govuk-summary-list__value govuk-!-padding-left-9">
      <app-summary-record-value
        [wdfView]="wdfView"
        [wdfValue]="worker.wdf?.highestQualification"
        [overallWdfEligibility]="overallWdfEligibility"
      >
        {{ worker.highestQualification?.title || '-' }}
      </app-summary-record-value>
      <app-wdf-field-confirmation
        *ngIf="
          canEditWorker &&
          wdfView &&
          worker.wdf?.highestQualification.isEligible === 'Yes' &&
          !worker.wdf?.highestQualification.updatedSinceEffectiveDate
        "
        [changeLink]="getRoutePath('other-qualifications-level',wdfView)"
        (fieldConfirmation)="this.confirmField('highestQualification'); this.confirmField('otherQualification')"
        (setReturnClicked)="this.setReturn()"
        [workerUid]="worker.uid"
      ></app-wdf-field-confirmation>
    </dd>
    <dd *ngIf="canEditWorker" class="govuk-summary-list__actions">
      <app-summary-record-change
        *ngIf="
          !(
            wdfView &&
            worker.wdf?.highestQualification.isEligible === 'Yes' &&
            !worker.wdf?.highestQualification.updatedSinceEffectiveDate
          )
        "
        [explanationText]="' other qualification level'"
        [link]="getRoutePath('other-qualifications-level', wdfView)"
        [hasData]="!!worker.highestQualification"
        (setReturnClicked)="this.setReturn()"
      ></app-summary-record-change>
    </dd>
  </div>
</dl>
